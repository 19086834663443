<template>
  <!-- Content Add Modal -->
  <b-modal centered id="study-years-add-modal" title="Tambah Tahun Ajaran">
    <div>
      <b-form-group
        label="Tahun Ajaran"
        :invalid-feedback="errors.period.message"
      >
        <b-form-input
          v-model="studyYearsContent.period"
          required
          :state="errors.period.state"
        />
      </b-form-group>
      <b-form-group label="Semester" :invalid-feedback="errors.name.message">
        <b-form-select
          v-model="studyYearsContent.name"
          :options="nameList"
          required
        />
      </b-form-group>

      <b-button
        v-if="studyYearsContent.isEdit"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="w-100"
        variant="primary"
        @click="editYear"
      >
        <i v-show="!isLoading" class="font-medium-2 ti ti-circle-plus" />
        <b-spinner v-show="isLoading" small variant="light" />
        Perbarui Tahun Ajaran
      </b-button>
      <b-button
        v-else
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="w-100"
        variant="primary"
        @click="addYear"
      >
        <i v-show="!isLoading" class="font-medium-2 ti ti-circle-plus" />
        <b-spinner v-show="isLoading" small variant="light" />
        Buat Tahun Ajaran
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    studyYearsContent: {
      type: Object,
      default: () => ({
        id: '',
        period: '',
        name: '',
        isEdit: false,
      }),
    },
  },
  data() {
    return {
      isLoading: false,
      errors: {
        period: {
          state: null,
          message: '',
        },
        name: {
          state: null,
          message: '',
        },
      },

      nameList: ['Gasal', 'Genap'],
    }
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    resetModal() {
      this.studyYearsContent.period = ''
      this.studyYearsContent.name = ''

      this.errors.period.state = null
      this.errors.name.state = null
    },
    formValidation() {
      this.isLoading = true
      let isValid = true

      if (this.studyYearsContent.period === '') {
        this.studyYearsContent.errors.period.state = false
        this.studyYearsContent.errors.period.message =
          'Tahun Ajaran Tidak Boleh Kosong'
        isValid = false
      }

      if (this.studyYearsContent.name === '') {
        this.studyYearsContent.errors.name.state = false
        this.studyYearsContent.errors.name.message =
          'Semester Tidak Boleh Kosong'
        isValid = false
      }

      return isValid
    },
    async addYear() {
      if (this.formValidation) {
        await client
          .post('/utils/school-years', {
            period: this.studyYearsContent.period,
            name: this.studyYearsContent.name,
            status: 'Active',
          })
          .then((response) => {
            this.isLoading = false
            this.$emit('refresh')

            this.resetModal()
            this.$bvModal.hide('study-years-add-modal')
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Tahun Ajaran Ditambahkan'
            )
          })
          .catch((error) => {
            this.isLoading = false
            this.showToast(
              'danger',
              'XCircleIcon',
              'Gagal',
              'Tahun Ajaran Gagal Ditambahkan'
            )
          })
      }
    },
    async editYear() {
      if (this.formValidation) {
        await client
          .post(`/utils/school-years/${this.studyYearsContent.id}`, {
            period: this.studyYearsContent.period,
            name: this.studyYearsContent.name,
            status: 'Active',
            _method: 'PUT',
          })
          .then((response) => {
            this.isLoading = false
            this.$emit('refresh')

            this.resetModal()
            this.$bvModal.hide('study-years-add-modal')
            this.showToast(
              'success',
              'CheckIcon',
              'Berhasil',
              'Tahun Ajaran Diperbarui'
            )
          })
          .catch((error) => {
            this.isLoading = false
            this.showToast(
              'danger',
              'XCircleIcon',
              'Gagal',
              'Tahun Ajaran Gagal Diperbarui'
            )
          })
      }
    },
  },
}
</script>

<style lang="scss">
#study-years-add-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
