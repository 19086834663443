<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <!-- Header -->
          <div class="d-flex align-items-center line-height-1 pb-2 ">
            <i class="text-primary ti ti-calendar-stats small-gap-right" />
            <h4 class="text-primary mb-0">Tahun Ajaran</h4>
          </div>

          <!-- Table of Student-->
          <div>
            <b-row>
              <b-col lg="1">
                <b-form-select
                  class="w-auto mr-1"
                  v-model="pageLength"
                  :options="options"
                />
              </b-col>
              <b-col></b-col>
              <b-col lg="4">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <label class="mr-1">Filter</label>
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search"
                      type="text"
                      class="d-inline-block mr-1"
                    />

                    <!-- add button -->
                    <b-button
                      variant="primary"
                      class="d-flex"
                      @click="handleAdd()"
                    >
                      <i class="ti ti-plus small-gap-right"></i>
                      Tambah
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span
                  v-if="props.column.field === 'status'"
                  class="d-flex justify-content-start"
                >
                  <b-form-checkbox
                    :checked="props.row.status === 'Active' ? true : false"
                    name="check-button"
                    switch
                    inline
                    @change="handleStatus(props.row)"
                  >
                    {{ props.row.status }}
                  </b-form-checkbox>
                </span>

                <!-- Column: Action -->
                <span
                  v-else-if="props.column.field === 'action'"
                  class="d-flex justify-content-start"
                >
                  <b-button
                    variant="warning"
                    size="sm"
                    class="mr-1"
                    @click="handleEdit(props.row)"
                  >
                    <i class="ti ti-pencil" />
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    class="mr-1"
                    @click="handleDelete(props.row.id)"
                  >
                    <i class="ti ti-trash" />
                  </b-button>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <b-form-select
                      v-model="pageLength"
                      hidden
                      class="w-auto mr-1"
                      :options="options"
                      @input="
                        props.perPageChanged({ currentPerPage: pageLength })
                      "
                    />
                    <span class="text-nowrap ">
                      Showing
                      {{
                        pageLength <= props.total
                          ? pageLength * currentPage
                          : props.total
                      }}
                      of {{ props.total }} entries
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          currentPage = value
                          props.pageChanged({ currentPage: value })
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- Modal Add -->
    <study-years-add-modal
      :study-years-content="studyYearsContent"
      @refresh="refreshTable()"
    />
  </div>
</template>

<script>
import StudyYearsAddModal from './StudyYearsAddModal.vue'
import { VueGoodTable } from 'vue-good-table'
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import client from '@/libs/http/axios-config'
import { pascalCase } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    VueGoodTable,
    StudyYearsAddModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Table option
      searchTerm: '',
      currentPage: 1,
      pageLength: 5,
      options: ['5', '10', '30'],
      columns: [
        {
          label: 'Tahun Ajaran',
          field: 'years',
        },
        {
          label: 'Semester',
          field: 'semester',
          width: '25%',
        },
        {
          label: 'Status',
          field: 'status',
          width: '15%',
        },
        {
          label: 'Aksi',
          field: 'action',
          width: '5%',
        },
      ],
      rows: [],

      // Modal Add
      studyYearsContent: {
        title: '',
        description: 'Gasal',
        isEdit: false,
      },
    }
  },
  async mounted() {
    await client
      .get('/utils/school-years')
      .then((response) => {
        this.rows = response.data.data.map((item) => {
          return {
            id: item.id,
            years: item.period,
            semester: item.name,
            status: item.status,
          }
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    resetStudyYearsContent() {
      this.studyYearsContent = {
        name: 'Gasal',
        period: '',
        isEdit: false,
      }
    },
    triggerModal() {
      this.$bvModal.show('study-years-add-modal')
    },
    handleAdd() {
      this.resetStudyYearsContent()
      this.triggerModal()
    },
    handleEdit(row) {
      this.studyYearsContent = {
        id: row.id,
        name: row.semester,
        period: row.years,
        isEdit: true,
      }
      this.triggerModal()
    },
    async handleStatus(row) {
      await client
        .post(`/utils/school-years/${row.id}`, {
          period: row.years,
          name: row.semester,
          status: row.status === 'Active' ? 'Inactive' : 'Active',
          _method: 'PUT',
        })
        .then(() => {
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Tahun Ajaran Diperbarui'
          )
          this.refreshTable()
        })
        .catch((error) => {
          this.isLoading = false
          this.showToast(
            'danger',
            'XCircleIcon',
            'Gagal',
            'Tahun Ajaran Gagal Diperbarui'
          )
        })
    },
    async handleDelete(yearId) {
      this.$swal({
        title: 'Hapus Tahun Ajaran?',
        text: 'Anda tidak akan dapat mengembalikan ini!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteYear(yearId)
        }
      })
    },

    async deleteYear(yearId) {
      await client
        .delete(`/utils/school-years/${yearId}`)
        .then(() => {
          this.showToast(
            'success',
            'CheckIcon',
            'Berhasil',
            'Tahun Ajaran Berhasil Dihapus'
          )
          this.refreshTable()
        })
        .catch((error) => {
          this.isLoading = false
          this.showToast(
            'danger',
            'XCircleIcon',
            'Gagal',
            'Tahun Ajaran Gagal Dihapus'
          )
        })
    },

    async refreshTable() {
      await client
        .get('/utils/school-years')
        .then((response) => {
          this.rows = response.data.data.map((item) => {
            return {
              id: item.id,
              years: item.period,
              semester: item.name,
              status: item.status,
            }
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.line-numbers {
  padding: 0 1.2em !important;
}
</style>
